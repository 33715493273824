import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { isEmpty, isPositiveNumber } from "@helpers";
import { VForm } from "@/types/vuetify";
import { profileModule, teamModule } from "@store/namespaces";
import { TeamActions } from "@store/modules/team/types";
import { ProfileGetters } from "@store/modules/profile/types";
import { SecondFactorCode } from "@/types/2fa";

@Component
export default class CardIssueLimit extends Vue {
  @teamModule.Action("updateTeamMember")
  private readonly updateTeamMemberAction!: TeamActions["updateTeamMember"];
  @profileModule.Getter
  private readonly profileSecondFactorEnabled!: ProfileGetters["profileSecondFactorEnabled"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: String, required: true }) private readonly email!: string;
  @Prop({ type: Number, required: true }) private numberOfActiveCards!: number;
  @Prop({ type: Number, required: true }) private cardIssueLimit!: number;

  private loading = false;
  private localCardIssueLimit = "";
  private secondFactorCode: SecondFactorCode = "";

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      limitAmount: (v: string) => {
        if (!isPositiveNumber(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.positive_number");
        }

        if (!Number.isSafeInteger(+v)) {
          return this.$vuetify.lang.t("$vuetify.errors.only_integer_number");
        }

        if (+v < this.numberOfActiveCards) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.limit_amount_card_issue",
            this.numberOfActiveCards
          );
        }

        return true;
      },
    };
  }

  private get disabledSubmitForm() {
    return this.fieldRules.limitAmount(this.localCardIssueLimit) !== true;
  }

  private async onSubmitForm() {
    if (this.loading || !this.formRef.validate()) return;

    this.loading = true;

    try {
      await this.updateTeamMemberAction({
        email: this.email,
        cardIssueLimit: +this.localCardIssueLimit,
        secondFactorCode: this.secondFactorCode,
      });

      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.info.set_limit_amount_success"),
      });

      this.$emit("success");
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  private onClickClose() {
    this.$emit("close");
  }

  private created() {
    this.localCardIssueLimit = this.cardIssueLimit.toString();
  }
}
